.css-b62m3t-container {
  @apply w-[99.75%] items-center justify-center pl-[0.5px] rtl:relative rtl:box-border rtl:w-[99.65%] rtl:pr-[1.5px];
}

input[type='Select']:focus + label,
input[type='Select']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-10 scale-100;
}

.select {
  @apply font-general rtl:font-almarai w-full border block  px-3 py-3 bg-white !border-gray text-secondary rounded outline-none;
}

.dropdown {
  @apply w-full text-light-black outline-none rounded text-sm transition duration-150 ease-in-out mb-8;
}

.your-selector {
  @media (min-width: theme('screens.lg')) {
  }
}

.text-area {
  @apply appearance-none resize-none  font-general rtl:font-almarai w-full  border block  px-3 py-2 text-secondary bg-white border-gray  rounded outline-none placeholder-white placeholder-opacity-0 transition duration-200;
}

.css-ygbhh5-singleValue {
  color: var(--secondary) !important;
}

.registrationselect:focus + label,
.registrationselect:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 ltr:-translate-x-[20px] rtl:-translate-x-[-1.5rem] scale-100;
}

.selectinput:focus + label,
.selectinput:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}
.lok:focus + label,
.lok:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}
