.modal {
  @apply fixed left-0 w-full z-50 top-0 h-screen bg-[#64636ab3];
}

.modal-main {
  transform: translate(-50%, -50%);
  @apply fixed bg-white max-w-[54.375rem] pb-4 min-h-[14rem] z-[1001] h-auto top-[50vh] left-[50vw] rounded-[10px] font-semibold;
}

.modal-content {
  @apply p-[5%] pb-0 max-h-[90vh] overflow-auto;
}

.modal-header {
  @apply fixed w-full h-[4.375rem] rounded-t-[5px] z-[2];
}

.modal-header-title {
  justify-content: initial;
  @apply w-full h-[4.375rem] bg-white text-white rounded-tl-[10px] flex items-center p-8 text-[12px] font-semibold;
}

.modal-header-close {
  @apply bg-white h-[4.375rem] w-[4.375rem] rounded-tr-[10px] flex justify-center items-center;
}
