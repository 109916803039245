.eyestrike {
  @apply absolute top-2.5 right-3 rtl:top-3 rtl:left-[0.625rem];
}

input[type='text']:focus + label,
input[type='text']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100 w-fit max-w-[95%];
}

input[type='number']:focus + label,
input[type='number']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-6 scale-100 w-fit max-w-[95%];
}

.inputs {
  @apply appearance-none  font-general rtl:font-almarai w-full border block  px-3 py-2 text-secondary bg-white border-gray  rounded outline-none placeholder-white placeholder-opacity-0 transition duration-200;
}

/* input[type='password']:focus + label,
input[type='password']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5  ltr:-translate-x-6 rtl:-translate-x-[-1.5rem] scale-100 w-fit max-w-[95%];
} */

/* input[type='email']:focus + label,
input[type='email']:not(:placeholder-shown) + label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5  ltr:-translate-x-6 rtl:-translate-x-[-1.5rem] scale-100 w-fit max-w-[95%];
} */

input[type='text']:focus ~ .icon,
input[type='text']:not(:placeholder-shown) ~ .icon {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 ltr:-translate-x-6 rtl:-translate-x-[-1.5rem] scale-100;
}

/* input[type='email']:focus ~ .inputlabel,
input[type='email']:not(:placeholder-shown) + .inputlabel {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 ltr:-translate-x-1 rtl:-translate-x-[0.25rem] scale-100;
}

input[type='password']:focus ~ .inputlabel,
input[type='password']:not(:placeholder-shown) + .inputlabel {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 ltr:-translate-x-1 rtl:-translate-x-[0.25rem] scale-100;
} */

input[type='number']:focus ~ .inputlabel,
input[type='number']:not(:placeholder-shown) + .inputlabel {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 -translate-x-1 scale-100;
}

input[type='number']:focus ~ .phone-label,
input[type='number']:not(:placeholder-shown) + .phone-label {
  @apply text-secondary text-12 font-semibold transform -translate-y-5 ltr:-translate-x-[75px]  rtl:-translate-x-[-75px] scale-100;
}
.sample {
  max-width: 90%;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
