.coach-session-container {
  /* height: calc(100vh - 4rem); */

  @apply w-full px-20   flex justify-between  pt-16  flex-row;
}
.price-input-container {
  @apply w-[30%];
}
.coaching-hr-container {
  @apply w-full flex gap-3 flex-row;
}
.coaching-hr-container-hour {
  @apply w-[30%];
}
.coaching-hr-container-day {
  @apply w-[40%];
}
.hour-input-container {
  @apply w-[70%] gap-3 flex;
}
.hour-input-container-box {
  @apply w-[30%];
}
.hour-input-container-AM {
  @apply w-[20%];
}
.session-form-container {
  /* box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.15); */
  @apply w-full  mb-12 p-8;
}
.session-image-container {
  @apply w-full h-fit flex;
}
.session-image-desc {
  @apply h-56 w-full px-5 py-8 flex flex-col justify-between;
}
.session-mobile-menu {
  @apply h-14 w-full p-5 px-0 mb-3 font-satoshi rtl:font-almarai relative flex justify-between font-bold text-2xl;
}
.session-mobile-menu-container {
  /* @apply hidden; */
}
.mobile-btn-div {
  @apply hidden;
}
@media screen and (max-width: 560px) {
  .coach-session-container {
    @apply px-5 pt-6;
  }
  .price-input-container {
    @apply w-[50%];
  }
  .hour-input-container {
    @apply w-[60%];
  }
}
.quill {
  border: 1px solid #687e8e;
  /* border-radius: 4px; */
  @apply flex flex-col-reverse font-general rtl:font-almarai rounded;
}
.ql-toolbar.ql-snow {
  @apply flex justify-end font-general rtl:font-almarai p-2 border-0;
}
.ql-container.ql-snow {
  @apply border-0;
}
.ql-toolbar.ql-snow .ql-formats {
  @apply m-0;
}
.ql-container {
  @apply font-general rtl:font-almarai  text-17 text-opacity-80;
}
.ql-editor.ql-blank::before {
  content: attr(data-placeholder);
  font-style: normal;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.react-multi-react-tags .css-1rhbuit-multiValue {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #cccccc;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}
.coach-session-nav {
  @apply w-[24%] h-[74vh] ltr:pr-5 rtl:pl-5 flex flex-col justify-between;
}
.session-dropdown-row {
  @apply flex w-full flex-row grid grid-cols-3 gap-6;
}
.session-image-default {
  @apply w-[30rem] h-56 rounded bg-[#F6F8F5] flex justify-center items-center;
}
.session-image {
  @apply w-[30rem];
}
.session-upload {
  @apply w-full flex items-center;
}
.session-upload-mobile {
  @apply w-full flex items-center hidden;
}
@media screen and (max-width: 1014px) {
  .coach-session-nav {
    @apply hidden;
  }
  .session-form-container {
    box-shadow: none;
    @apply w-full p-0;
  }
  .session-mobile-menu-container {
    @apply block;
  }
  .mobile-btn-div {
    @apply block;
  }
  .coach-session-container {
    @apply flex-col;
  }
  .coaching-hr-container-day {
    @apply w-[100%];
  }
}
@media screen and (max-width: 650px) {
  .session-dropdown-row {
    @apply flex flex-col grid-cols-1 gap-0;
  }
}
@media screen and (max-width: 750px) {
  .session-image-desc {
    @apply px-0 pt-0 h-fit;
  }
  .session-image-container {
    @apply flex-col-reverse;
  }
  .session-image-default {
    @apply w-full;
  }
  .session-image {
    @apply w-full;
  }
  .session-upload {
    @apply hidden;
  }
  .session-upload-mobile {
    @apply w-full flex items-center block;
  }
  .coaching-hr-container {
    @apply flex-col;
  }
  .coaching-hr-container-hour {
    @apply w-[50%] pt-3;
  }
  /* .hour-input-container {
    @apply grid  grid-cols-2 gap-3;
  } */
  .hour-input-container {
    @apply flex-wrap;
  }
  .hour-input-container-box {
    @apply w-[55%];
  }
  .hour-input-container-AM {
    @apply w-[35%];
  }
}
.coach-session-dropdown-container {
  box-shadow: 0px 2px 15px rgb(47 74 110 / 5%);
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 50px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--white);
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -6px;
  bottom: -5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}
.description-box-error {
  border: 1px solid var(--red);
}
.check-box-intended input[type='checkbox'] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: black;
  border: 1px solid #cccccc;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
.check-box-intended input[type='checkbox']::before {
  position: absolute;
  content: '';
  display: block;
  top: 2px;
  left: 7px;
  width: 8px;
  height: 14px;
  border-style: solid;
  border-color: #02a4ea;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.check-box-intended input[type='checkbox']:checked {
  color: white;
  border-color: white;
  background: white;
}
.check-box-intended input[type='checkbox']:checked::before {
  opacity: 1;
}
