@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #00a5ec;
  --secondary: #153243;
  --gray: #687e8e;
  --info: #c3cbd1;
  --white: #ffffff;
  --light-black: #5d707b;
  --yellow: #f5b239;
  --red: #bc455e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='text']:focus + label,
input[type='text']:not(:placeholder-shown) + label {
  @apply transform -translate-y-5 -translate-x-1 text-12 font-bold text-textBlack;
}

input[type='password']:focus + label,
input[type='password']:not(:placeholder-shown) + label {
  @apply transform -translate-y-5 -translate-x-1 text-12 font-bold text-textBlack;
}

input[type='email']:focus + label,
input[type='email']:not(:placeholder-shown) + label {
  @apply transform -translate-y-5 -translate-x-1 text-12 font-bold text-textBlack;
}
input[type='date']:focus + label,
input[type='date']:not(:placeholder-shown) + label {
  @apply transform -translate-y-6 -translate-x-2 scale-100;
}

input[type='number']:focus + label,
input[type='number']:not(:placeholder-shown) + label {
  @apply transform -translate-y-6 -translate-x-2 scale-100 placeholder:visible;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}
input[type='search']::-webkit-search-cancel-button {
  margin-right: 10px;
  max-resolution: 3px;
  cursor: pointer;
}

textarea[type='text']:focus + label,
textarea[type='text']:not(:placeholder-shown) + label {
  @apply transform -translate-y-6 -translate-x-2 scale-100;
}

.input_login {
  @apply appearance-none w-full  text-17  font-normal text-black font-general rtl:font-almarai   border border-solid relative block  px-3 py-3  bg-white  rounded  outline-none  placeholder-white placeholder-opacity-0 transition duration-200;
}

.label-input-login {
  @apply text-17 left-3 top-3  rtl:right-2 rtl:w-fit font-normal  cursor-text font-general rtl:font-almarai  bg-white absolute  px-1 transition duration-200 text-gray;
}

.p-text {
  @apply font-normal font-general rtl:font-almarai text-base text-gray my-4;
}
.inputs {
  @apply appearance-none  font-general rtl:font-almarai w-full mt-5 border block  px-3 py-2 text-secondary bg-white border-gray  rounded border-opacity-50 outline-none placeholder-white placeholder-opacity-0 transition duration-200;
}
.landing-heading {
  @apply font-satoshi rtl:font-almarai font-bold text-34 text-secondary mb-4;
}

.landing-paragraph {
  @apply font-general rtl:font-almarai font-normal text-17.5 text-light-black;
}

.footer-list {
  @apply text-base font-normal text-white not-italic my-3;
}
.footer-head {
  @apply block text-xl not-italic font-medium mb-6;
}
.icon-btn {
  @apply mr-5 inline-block p-3 mb-2 bg-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out;
}

.btn-small {
  @apply font-normal text-16 font-general rtl:font-almarai px-4 py-2 border rounded mr-4;
}

.header_link_text {
  @apply font-normal text-16 font-general rtl:font-almarai;
}

.btn-submit {
  @apply bg-primary w-full py-3 px-4 text-sm text-white rounded;
}

.review::-webkit-scrollbar {
  display: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.review {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.thin_scrollBar {
  scrollbar-width: thin;
  padding-left: 5px;
  scrollbar-color: var(--secondary) var(--primary);
}
.thin_scrollBar::-webkit-scrollbar {
  width: 8px;
}
.thin_scrollBar::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}
.thin_scrollBar::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--secondary);
}
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary);
  @apply w-5 h-5 animate-spin rounded-[50%] mx-4;
}
